import { twMerge } from "tailwind-merge";
import Image from "next/image";
import * as PrimitiveCheckbox from "@radix-ui/react-checkbox";

export function GreaterAffixCheckbox({
  className,
  ...rest
}: PrimitiveCheckbox.CheckboxProps): JSX.Element {
  return (
    <PrimitiveCheckbox.Root
      {...rest}
      className={twMerge(
        "focus-visible:ring-ring h-6 w-6 shrink-0 rounded-sm border  border-[white]/25 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#A61010]",
        className,
      )}
    >
      <PrimitiveCheckbox.Indicator className="relative block h-full w-full bg-[image:url('/images/decorations//greater-affix.webp')] bg-contain">
        <Image
          alt="Greater Affix"
          fill
          src="/assets/front_end/card/decorations/greater-affix.webp"
        />
      </PrimitiveCheckbox.Indicator>
    </PrimitiveCheckbox.Root>
  );
}
