import { forwardRef } from "react";
import Image from "next/image";
import * as Select from "@radix-ui/react-select";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import type { GamePlayMode, GamplayModeOption } from "@/types";

export const GameplayModeSelect = forwardRef<
  React.ElementRef<typeof Select.Root>,
  Omit<React.ComponentPropsWithoutRef<typeof Select.Root>, "value"> & {
    value?: GamePlayMode;
    options: GamplayModeOption[];
  }
>(({ value, options = [], ...props }, forwardedRef) => {
  const imageSrc: Record<GamePlayMode, string> = {
    hardcore: "/images/modes/hardcore.png",
    softcore: "/images/modes/softcore.png",
    seasonal_hardcore: "/images/modes/seasonal_hardcore.webp",
    seasonal_softcore: "/images/modes/seasonal_softcore.webp",
    eternal_hardcore: "/images/modes/eternal_hardcore.png",
    eternal_softcore: "/images/modes/eternal_softcore.png",
  };

  return (
    <Select.Root {...props} value={value}>
      <Select.Trigger
        className="flex h-8 w-full items-center justify-between bg-gray-500 px-2 font-diablo"
        ref={forwardedRef}
      >
        {value ? (
          <span className="flex h-[16px] w-[20px]">
            <Image
              alt=""
              height={20}
              src={imageSrc[value as GamePlayMode]}
              width={16}
            />
          </span>
        ) : null}

        <span className="flex-1 truncate px-1 text-left">
          <Select.Value placeholder="Select" />
        </span>
        <Select.Icon className="ml-2">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className="relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-sm bg-gray-600 shadow-lg data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1"
          position="popper"
        >
          <Select.Viewport className="h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)] p-1">
            {options.map((option) => {
              return (
                <Select.Item
                  className="relative flex h-8 w-full min-w-[var(--radix-select-trigger-width)] cursor-default items-center gap-2 rounded-sm px-2 py-1.5 pr-8 font-diablo hover:bg-background-emphasis hover:bg-gray-700"
                  key={option.value}
                  value={option.value}
                >
                  <Select.ItemIndicator className=" absolute right-2 h-4 w-4">
                    <CheckIcon className="h-4 w-4" />
                  </Select.ItemIndicator>
                  <Image
                    alt=""
                    height={20}
                    src={imageSrc[option.value]}
                    width={16}
                  />

                  <Select.ItemText>{option.label}</Select.ItemText>
                </Select.Item>
              );
            })}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
});

GameplayModeSelect.displayName = "GameplayModeSelect";
